import React, { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Mouse = ({ isNegative }) => {
  const layerRef = useRef(null)
  const [isHover, setIsHover] = useState(false)
  const classes = cx('mouse', {
    'is-hidden': /android.+mobile|ip(hone|[oa]d)/i.test(navigator.userAgent),
    'is-hover': isHover,
    'is-negative': isNegative
  })

  useLayoutEffect(() => {
    document.addEventListener('mousemove', e => {
      const posX = e.clientX
      const posY = e.clientY
      const targetClick = e.target.getAttribute('data-click')

      layerRef.current.style.transform = `translate3d(calc(-50% + ${posX}px), calc(-50% + ${posY}px), 0)`

      targetClick || e.target.tagName === 'A' ? setIsHover(true) : setIsHover(false)
    })
  }, [])

  return (
    <div className={classes} ref={layerRef}></div>
  )
}

Mouse.propTypes = {
  isNegative: PropTypes.bool
}

export default Mouse
