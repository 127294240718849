import React, { useCallback, useEffect, useRef, useState } from 'react'
// import PropTypes from 'prop-types'
import cx from 'classnames'
import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import Carousel from '../carousel/Carousel'

const ProjectDetail = ({
  carousel,
  isOpen,
  images,
  isNegative,
  tag,
  title,
  subtitle,
  location,
  photography,
  styling,
  talent,
  model,
  hair,
  makeUp,
  designed,
  artDirection,
  creativeDirection,
  set,
  production,
  casting,
  artist,
  gallery,
  concept,
  details,
  detailType,
  video,
  index,
  url
}) => {
  const [carouselImages, setCarouselImages] = useState([])
  const scrollRef = useRef(null)
  const titleRef = useRef(null)
  const tagRef = useRef(null)
  const subtitleRef = useRef(null)
  const creditsRef = useRef(null)
  const videoRef = useRef(null)
  const classes = cx('projectDetail', {
    'is-negative': isNegative,
    'is-open': isOpen === index
  })

  const resize = images => images.forEach(image => {
    image.parentNode.style.width = 'auto'
    image.parentNode.style.width = `${image.clientWidth}px`
  })

  const resizeParallax = selector => {
    const images = selector.querySelectorAll('.projectDetail-parallaxImage')
    resize(images)
    window.addEventListener('resize', () => resize(images))
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(SplitText)

    if (images && isOpen === index) {
      class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
        static pluginName = 'horizontalScroll'
        transformDelta(delta) {
          const { x, y } = delta
          return {
            y: 0,
            x: Math.abs(x) > Math.abs(y) ? x : y
          }
        }
      }

      Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin)

      const myHorizontalScrollbar = Scrollbar.init(scrollRef.current, {
        damping: 0.3,
        delegateTo: scrollRef.current,
        renderByPixels: true
      })

      myHorizontalScrollbar.setPosition(0, 0)
      ScrollTrigger.scrollerProxy(scrollRef.current, {
        scrollLeft(value) {
          if (arguments.length) myHorizontalScrollbar.scrollLeft = value
          return myHorizontalScrollbar.scrollLeft
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          }
        }
      })

      myHorizontalScrollbar.addListener(ScrollTrigger.update)

      const split = new SplitText(titleRef.current)
      const parallaxes = document.querySelectorAll('.projectDetail-parallax')
      const images = document.querySelectorAll('.projectDetail-parallaxImage')
      const carousel = document.querySelector('.carousel')
      const carouselImages = document.querySelectorAll('.carousel-parallaxImage')

      gsap.timeline()
        .from(split.chars, {
          y: '50%',
          opacity: 0,
          duration: 0.9,
          ease: 'power2.out',
          stagger: 0.03
        })
        .from([parallaxes, carousel], {
          opacity: 0,
          duration: 1.2,
        }, '-=0.6')
        .from(tagRef.current, {
          y: '66.66%',
          opacity: 0,
          duration: 1.2
        }, '-=0.6')
        .from([subtitleRef.current, creditsRef.current], {
          y: '33.33%',
          opacity: 0,
          duration: 1.2
        }, '-=1.2')
      images.forEach(image => {
        gsap.to(image, {
          x: '-15%',
          scrollTrigger: {
            trigger: image,
            horizontal: true,
            scroller: scrollRef.current,
            scrub: 1
          },
        })
      })
      if (carouselImages.length > 0) carouselImages.forEach(image => {
        gsap.to(image, {
          x: '-15%',
          scrollTrigger: {
            trigger: image,
            horizontal: true,
            scroller: scrollRef.current,
            scrub: 1
          },
        })
      })

      resizeParallax(scrollRef.current)
    }

    if (carousel.length > 0) setCarouselImages(carousel.map(image => `${url}${image.url}`))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, isOpen, index, carousel, url])

  const playVideo = useCallback(() => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    } else if (videoRef.current.webkitRequestFullscreen) { /* Safari */
      videoRef.current.webkitRequestFullscreen()
    } else if (videoRef.current.msRequestFullscreen) { /* IE11 */
      videoRef.current.msRequestFullscreen()
    }
    videoRef.current.play()

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      }
    })
    document.addEventListener('webkitfullscreenchange', () => {
      if (!document.webkitFullscreenElement) {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      }
    })
  }, [])

  return (
    <section className={classes}>
      <article className="projectDetail-project">
        <div className="project-info mainWrapper">
          <h5 className="project-tag" ref={tagRef}>{tag}</h5>
          <h3 className="project-title" ref={titleRef}>{title}</h3>
          <h4 className="project-subtitle" ref={subtitleRef}>{subtitle}</h4>
          <div className="project-credits" ref={creditsRef}>
            {details
              ? <p className="project-credit is-details">{details}</p>
              : <>
                {location ?
                  <p className="project-credit">Location <span>{location} </span></p>
                : null}
                {photography ?
                  <p className="project-credit">Photography <span>{photography} </span></p>
                : null}
                {styling ?
                  <p className="project-credit">Styling <span>{styling}</span></p>
                : null}
                {talent ?
                  <p className="project-credit">Talent <span>{talent}</span></p>
                : null}
                {model ?
                  <p className="project-credit">Model <span>{model}</span></p>
                : null}
                {hair ?
                  <p className="project-credit">Hair <span>{hair}</span></p>
                : null}
                {makeUp ?
                  <p className="project-credit">Make up <span>{makeUp}</span></p>
                : null}
                {designed ?
                  <p className="project-credit">Designed by <span>{designed} </span></p>
                : null}
                {artDirection ?
                  <p className="project-credit">Art Director <span>{artDirection} </span></p>
                : null}
                {creativeDirection ?
                  <p className="project-credit">Creative Direction <span>{creativeDirection} </span></p>
                : null}
                {set ?
                  <p className="project-credit">Set <span>{set} </span></p>
                : null}
                {production ?
                  <p className="project-credit">Production <span>{production} </span></p>
                : null}
                {casting ?
                  <p className="project-credit">Casting <span>{casting} </span></p>
                : null}
                {artist ?
                  <p className="project-credit">Artist <span>{artist} </span></p>
                : null}
                {gallery ?
                  <p className="project-credit">Gallery <span>{gallery} </span></p>
                : null}
                {concept ?
                  <p className="project-credit">Concept <span>{concept} </span></p>
                : null}
              </>
            }
          </div>
        </div>
      </article>
      <div className={`projectDetail-scroll is-${detailType}`} ref={scrollRef}>
        {images ? images.map((image, index) => <React.Fragment key={index}>
          {carousel.length > 0 && detailType === 'b' && index === 2
            ? <Carousel images={carouselImages} />
          : null}
          {carousel.length > 0 && detailType === 'c' && index === 6
            ? <Carousel images={carouselImages} />
          : null}
          <div className={`projectDetail-parallax`} key={index}>
            {video && index === 4
              ? <div className="projectDetail-player">
                  <video ref={videoRef} controls>
                    <source src={`${url}${video.url}`} type="video/mp4" />
                  </video>
                </div>
            : null}
            <img
              src={`${url}${image.url}`}
              className="projectDetail-parallaxImage"
              alt="parallax"
              data-click={video && index === 4 ? true : null}
              onClick={video && index === 4 ? playVideo : null}
            />
            {video && index === 4
              ? <p className="projectDetail-playerText">play</p>
            : null}
          </div>
        </React.Fragment>): null}
      </div>
    </section>
  )
}


ProjectDetail.defaultProps = {
  isOpen: false
}

// ProjectDetail.propTypes = {
//   isOpen: PropTypes.bool
// }

export default ProjectDetail
