import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Scrollbar from 'smooth-scrollbar'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import showdown from 'showdown'
import Footer from '../footer/Footer'

const ProjectDetail = ({
  isOpen,
  isVertical,
  areas,
  clients,
  description,
  image,
  skills
}) => {
  const converter = new showdown.Converter()
  const containerRef = useRef(null)
  const parallaxRef = useRef(null)
  const imageRef = useRef(null)
  const descriptionRef = useRef(null)
  const section01Ref = useRef(null)
  const section02Ref = useRef(null)
  const section03Ref = useRef(null)
  const classes = cx('about', {
    'is-open': isOpen,
    'is-vertical': isVertical
  })

  useEffect(() => {
    const bodyScrollBar = Scrollbar.init(containerRef.current, {
      damping: /android.+mobile|ip(hone|[oa]d)/i.test(navigator.userAgent) ? 0.033 : 0.3,
      delegateTo: containerRef.current,
      renderByPixels: true
    })

    ScrollTrigger.scrollerProxy(containerRef.current, {
      scrollTop(value) {
        if (arguments.length) bodyScrollBar.scrollTop = value
        return bodyScrollBar.scrollTop
      }
    })

    bodyScrollBar.addListener(ScrollTrigger.update)
    ScrollTrigger.defaults({ scroller: containerRef.current })

    if (isOpen) {
      gsap.timeline()
        .from(imageRef.current, {
          opacity: 0,
          duration: 1.2,
        })
        .from(descriptionRef.current, {
          y: '6.25%',
          opacity: 0,
          duration: 1.2,
          ease: 'power2.out'
        })
        .from([section01Ref.current, section02Ref.current, section03Ref.current], {
          x: '6.25%',
          opacity: 0,
          duration: 1.2,
          stagger: 0.3,
          ease: 'power2.out'
        }, '-=0.6')

      gsap.to(imageRef.current, {
        yPercent: 20,
        ease: 'none',
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: true,
        }
      })
      gsap.from(parallaxRef.current, {
        y: '50%',
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: 1
        }
      })
    }
  }, [isOpen])

  return (
    <section className={classes} ref={containerRef}>
      <div className="mainWrapper">
        <div className="about-parallax" ref={parallaxRef}>
          <img className="about-parallaxImage" src={image} alt="parallax" ref={imageRef} />
        </div>
        <article className="about-info">
          <h3 className="about-infoDescription" ref={descriptionRef}>{description}</h3>
          <div className="about-infoSection" ref={section01Ref}>
            <span className="about-infoSectionTitle">Areas</span>
              <p className="about-infoSectionTags">{areas}</p>
          </div>
          <div className="about-infoSection" ref={section02Ref}>
            <span className="about-infoSectionTitle">Skills</span>
            <p className="about-infoSectionTags">{skills}</p>
          </div>
          <div className="about-infoSection" ref={section03Ref}>
            <span className="about-infoSectionTitle">Brands</span>
            <div className="about-infoSectionTags" dangerouslySetInnerHTML={{__html: converter.makeHtml(clients)}}></div>
          </div>
        </article>
        <Footer hasLinkedin />
      </div>
    </section>
  )
}

ProjectDetail.propTypes = {
  isOpen: PropTypes.bool,
  isVertical: PropTypes.bool,
  areas: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  skills: PropTypes.string
}

export default ProjectDetail
