import React from 'react'

const ProjectsContainer = ({ children }) => {
  return (
    <section className="projectsContainer">
      <div className="projectsContainer-projects mainWrapper">
        {children}
      </div>
    </section>
  )
}

export default ProjectsContainer
