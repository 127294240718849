import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Loader = ({ isLoaded, isOpen }) => {
  const containerRef = useRef(null)
  const classes = cx('loader', {
    'is-open': isOpen
  })

  return (
    <section className={classes} ref={containerRef}></section>
  )
}

Loader.propTypes = {
  isOpen: PropTypes.bool
}

export default Loader
