import React, { useEffect, useState } from 'react'
import axios from 'axios'
import useViewportHeight from './ui/hooks/useViewportHeight'
import Header from './ui/components/header/Header'
import ScrollContainer from './ui/components/scroll-container/ScrollContainer'
import Hero from './ui/components/hero/Hero'
import ProjectsContainer from './ui/components/projects/ProjectsContainer'
import Project from './ui/components/projects/Project'
import Footer from './ui/components/footer/Footer'
import About from './ui/components/about/About'
import ProjectDetail from './ui/components/projects/ProjectDetail'
import Loader from './ui/components/loader/Loader'
import Mouse from './ui/components/mouse/Mouse'

import './ui/assets/stylesheets/style.scss'

const App = () => {
  const API_URL = 'https://belenlopez.studio/api'

  // Booleans
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoaderOpen, setIsLoaderOpen] = useState(true)
  const [isAboutOpen, setIsAboutOpen] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)
  const [isNegative, setIsNegative] = useState(false)

  // Data
  const [aboutData, setAboutData] = useState(null)
  const [projectsData, setProjectsData] = useState(null)

  const homeClick = () => {
    setIsLoaderOpen(true)
    setIsNegative(false)

    setTimeout(() => {
      setIsAboutOpen(false)
      setDetailOpen(false)
      setIsLoaderOpen(false)
    }, 1400)
  }

  const openAbout = () => {
    setIsLoaderOpen(true)
    setIsNegative(true)

    setTimeout(() => {
      setDetailOpen(false)
      setIsAboutOpen(true)
      setIsLoaderOpen(false)
    }, 1400)
  }

  const openDetail = (isNegative, index) => {
    setIsLoaderOpen(true)
    setIsNegative(isNegative ? true : false)

    setTimeout(() => {
      setDetailOpen(index)
      setIsLoaderOpen(false)
    }, 1400)
  }

  useEffect(() => {
    const fetchData = async () => {
      const requests = await Promise.all([
        axios.get(`${API_URL}/about`),
        axios.get(`${API_URL}/projects`)
      ])

      if (requests) {
        const projects = requests[1].data
        const orderedProjects = projects.sort((a, b) => a.order - b.order)

        setAboutData(requests[0])
        setProjectsData(orderedProjects)
        setIsLoaded(true)
        setIsLoaderOpen(false)
      }
    }

    fetchData()
  }, [])

  useViewportHeight()

  return (
    <div className="App">
      <Header
        isAboutOpen={isAboutOpen}
        isDetailOpen={detailOpen}
        isLoaderOpen={isLoaderOpen}
        isNegative={isNegative}
        onClickHome={homeClick}
        onClickAbout={openAbout}
      />
      <ScrollContainer isLoaded={isLoaded}>
        <Hero isLoaded={isLoaded} />
        <ProjectsContainer>
          {projectsData ? projectsData.map((project, index) => (
            <Project
              onClick={openDetail}
              isNegative={project.isBlack}
              tag={project.tag}
              title={project.title}
              subtitle={project.subtitle}
              location={project.location}
              photography={project.photography}
              styling={project.styling}
              talent={project.talent}
              model={project.model}
              hair={project.hair}
              makeUp={project.makeUp}
              designed={project.designed}
              artDirection={project.artDirection}
              creativeDirection={project.creativeDirection}
              set={project.set}
              production={project.production}
              casting={project.casting}
              artist={project.artist}
              gallery={project.gallery}
              concept={project.concept}
              image={`${API_URL}${project.image.url}`}
              imageType={project.imageType}
              images={project.images}
              detailType={project.detailType}
              index={index}
              key={index}
            />
          )) : null}
        </ProjectsContainer>
        <Footer />
      </ScrollContainer>
      <About
        isOpen={isAboutOpen}
        isVertical={aboutData ? aboutData.data.isVertical : false}
        image={aboutData ? `${API_URL}${aboutData.data.image.url}` : null}
        description={aboutData ? aboutData.data.description : null}
        areas={aboutData ? aboutData.data.areas : null}
        skills={aboutData ? aboutData.data.skills : null}
        clients={aboutData ? aboutData.data.clients : null}
      />
      {projectsData ? projectsData.map((project, index) => (
        <ProjectDetail
          isOpen={detailOpen}
          isNegative={project.isBlack}
          tag={project.tag}
          title={project.title}
          subtitle={project.subtitle}
          location={project.location}
          photography={project.photography}
          styling={project.styling}
          talent={project.talent}
          model={project.model}
          hair={project.hair}
          makeUp={project.makeUp}
          designed={project.designed}
          artDirection={project.artDirection}
          creativeDirection={project.creativeDirection}
          set={project.set}
          production={project.production}
          casting={project.casting}
          artist={project.artist}
          gallery={project.gallery}
          concept={project.concept}
          details={project.details ? project.details : null}
          images={project.images}
          detailType={project.detailType}
          carousel={project.carousel}
          video={project.video}
          index={index}
          key={index}
          url={API_URL}
        />
      )) : null}
      <Loader isOpen={isLoaderOpen} />
      <Mouse isNegative={isNegative} />
    </div>
  )
}

export default App
