import React from 'react'

const Footer = ({ hasLinkedin }) => {
  return (
    <section className="footer mainWrapper">
      <a href="mailto:hello@belenlopez.studio" title="mail" data-click>hello@belenlopez.studio</a>
      {hasLinkedin
        ? <a href="https://www.linkedin.com/in/bel%C3%A9n-l%C3%B3pez-s%C3%A1nchez-252a2227/" title="linkedin" target="_blank" rel="noreferrer" data-click>linkedin</a>
      : null}
    </section>
  )
}

export default Footer
