import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Scrollbar from 'smooth-scrollbar'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

const ScrollContainer = ({ isLoaded, children }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(SplitText)

    if (isLoaded) {
      const bodyScrollBar = Scrollbar.init(containerRef.current, {
        damping: 0.3,
        delegateTo: containerRef.current,
        renderByPixels: true
      })

      ScrollTrigger.scrollerProxy(containerRef.current, {
        scrollTop(value) {
          if (arguments.length) bodyScrollBar.scrollTop = value
          return bodyScrollBar.scrollTop
        }
      })

      bodyScrollBar.addListener(ScrollTrigger.update)
      ScrollTrigger.defaults({ scroller: containerRef.current })

      const projects = document.querySelectorAll('.project')
      projects.forEach(project => {
        const title = project.querySelector('.project-title')
        const tag = project.querySelector('.project-tag')
        const subtitle = project.querySelector('.project-subtitle')
        const credits = project.querySelector('.project-credits')
        const parallax = project.querySelector('.project-parallax')
        const image = project.querySelector('.project-parallaxImage')

        const split = new SplitText(title)

        gsap.timeline({
          scrollTrigger: {
            trigger: project,
            start: 'top 50%',
          }
        })
          .from(split.chars, {
            y: '50%',
            opacity: 0,
            duration: 0.9,
            ease: 'power2.out',
            stagger: 0.03
          })
          .from(image, {
            opacity: 0,
            duration: 1.2,
          }, '-=0.9')
          .from(tag, {
            y: '66.66%',
            opacity: 0,
            duration: 1.2
          }, '-=0.6')
          .from([subtitle, credits], {
            y: '33.33%',
            opacity: 0,
            duration: 1.2
          }, '-=1.2')

        gsap.fromTo(image, {
          y: '0%',
          scrollTrigger: {
            trigger: project,
            scrub: 1
          },
        }, {
          y: '-15%',
          scrollTrigger: {
            trigger: project,
            scrub: 1
          },
        })

        gsap.from(parallax, {
          y: '100%',
          scrollTrigger: {
            trigger: project,
            scrub: 1
          }
        })
      })
    }
  }, [isLoaded])

  return (
    <section className="scrollContainer" ref={containerRef}>
      {children}
    </section>
  )
}

ScrollContainer.propTypes = {
  isLoaded: PropTypes.bool,
  children: PropTypes.node
}

export default ScrollContainer
