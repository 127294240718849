import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Project = ({
  isNegative,
  onClick,
  tag,
  title,
  subtitle,
  location,
  photography,
  styling,
  talent,
  model,
  hair,
  makeUp,
  designed,
  artDirection,
  creativeDirection,
  set,
  production,
  casting,
  artist,
  gallery,
  concept,
  image,
  imageType,
  index
}) => {
  const imageClasses = cx('project-parallaxContainer', {
    'is-type01': imageType === 1,
    'is-type02': imageType === 2,
    'is-type03': imageType === 3,
    'is-type04': imageType === 4,
    'is-type05': imageType === 5
  })

  return (
    <article className="project">
      <div className={imageClasses}>
        <div className="project-parallax">
          <img className="project-parallaxImage" src={image} alt="parallax" />
        </div>
      </div>
      <div className="project-info">
        <h5 className="project-tag">{tag}</h5>
        <h3 className="project-title" onClick={() => onClick(isNegative, index)} data-click>{title}</h3>
        <h4 className="project-subtitle" onClick={() => onClick(isNegative, index)} data-click>{subtitle}</h4>
        <div className="project-credits">
          {location ?
            <p className="project-credit">Location <span>{location} </span></p>
          : null}
          {photography ?
            <p className="project-credit">Photography <span>{photography} </span></p>
          : null}
          {styling ?
            <p className="project-credit">Styling <span>{styling}</span></p>
          : null}
          {talent ?
            <p className="project-credit">Talent <span>{talent}</span></p>
          : null}
          {model ?
            <p className="project-credit">Model <span>{model}</span></p>
          : null}
          {hair ?
            <p className="project-credit">Hair <span>{hair}</span></p>
          : null}
          {makeUp ?
            <p className="project-credit">Make up <span>{makeUp}</span></p>
          : null}
          {designed ?
            <p className="project-credit">Designed by <span>{designed} </span></p>
          : null}
          {artDirection ?
            <p className="project-credit">Art Director <span>{artDirection} </span></p>
          : null}
          {creativeDirection ?
            <p className="project-credit">Creative Direction <span>{creativeDirection} </span></p>
          : null}
          {set ?
            <p className="project-credit">Set <span>{set} </span></p>
          : null}
          {production ?
            <p className="project-credit">Production <span>{production} </span></p>
          : null}
          {casting ?
            <p className="project-credit">Casting <span>{casting} </span></p>
          : null}
          {artist ?
            <p className="project-credit">Artist <span>{artist} </span></p>
          : null}
          {gallery ?
            <p className="project-credit">Gallery <span>{gallery} </span></p>
          : null}
          {concept ?
            <p className="project-credit">Concept <span>{concept} </span></p>
          : null}
        </div>
      </div>
    </article>
  )
}

Project.propTypes = {
  images: PropTypes.array,
  // isNegative: PropTypes.bool,
  onClick: PropTypes.func,
  tag: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  location: PropTypes.string,
  photography: PropTypes.string,
  styling: PropTypes.string,
  talent: PropTypes.string,
  model: PropTypes.string,
  hair: PropTypes.string,
  makeUp: PropTypes.string,
  designed: PropTypes.string,
  artDirection: PropTypes.string,
  creativeDirection: PropTypes.string,
  set: PropTypes.string,
  production: PropTypes.string,
  casting: PropTypes.string,
  artist: PropTypes.string,
  gallery: PropTypes.string,
  concept: PropTypes.string,
  image: PropTypes.string,
  detailType: PropTypes.string,
  imageType: PropTypes.oneOf([1, 2, 3, 4, 5]),
}

export default Project
