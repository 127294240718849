import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'

const Hero = ({ isLoaded }) => {
  const contianerRef = useRef(null)
  const titleRef = useRef(null)
  const subtitleRef = useRef(null)

  useEffect(() => {
    gsap.registerPlugin(SplitText)

    const split = new SplitText(titleRef.current)
    const header = document.querySelector('.header')

    if (isLoaded) gsap.timeline()
      .from(split.chars, {
        y: '100%',
        duration: 1.4,
        delay: 0.7,
        ease: 'power2.out',
        stagger: 0.04
      })
      .from(subtitleRef.current, {
        y: '100%',
        opacity: 0,
        duration: 1.4
      }, '-=0.7')
      .fromTo([titleRef.current, subtitleRef.current], {
        color: '#ffffff'
      }, {
        color: '#000000',
        duration: 1.4
      })
      .to(contianerRef.current, {
        backgroundColor: '#ffffff',
        duration: 1.4
      }, '-=1.4')
      .fromTo(header, {
        opacity: 0,
        pointerEvents: 'none'
      }, {
        opacity: 1,
        pointerEvents: 'all',
        duration: 1.4
      })
  }, [isLoaded])

  return (
    <section className="hero" ref={contianerRef}>
      <div className="hero-wrapper mainWrapper">
        <div className="hero-titleWrapper">
          <h1 className="hero-title" ref={titleRef}>
            <span className="hero-titleMask">Belén</span>
            <span className="hero-titleMask">López</span>
          </h1>
        </div>
        <h2 className="hero-subtitle" ref={subtitleRef}>FASHION DESIGN & CONSULTING</h2>
      </div>
    </section>
  )
}

Hero.propTypes = {
  isLoaded: PropTypes.bool
}

export default Hero
