import React, { useEffect, useState } from 'react'
import cx from 'classnames'

const Carousel = ({ images }) => {
  const [activeImage, setActiveImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage(activeImage < (images.length - 1) ? activeImage + 1 : 0)
    }, 2500)

    return () => clearInterval(interval)
  }, [activeImage, images])

  return (
    <div className="carousel">
      <div className="carousel-parallax">
        {images.map((image, index) => (
          <img
            className={cx('carousel-parallaxImage', { 'is-active': index === activeImage })}
            src={image} alt="parallax"
            key={index}
          />
        ))}
      </div>
      {/* <p className="carousel-counter">0{activeImage + 1}—0{images.length}</p> */}
    </div>
  )
}

export default Carousel
