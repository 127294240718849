import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Header = ({
  isAboutOpen,
  isDetailOpen,
  isNegative,
  isLoaderOpen,
  onClickAbout,
  onClickHome
}) => {
  const classes = cx('header mainWrapper', {
    'is-aboutOpen': isAboutOpen,
    'is-detailOpen': isDetailOpen !== false,
    'is-negative': isNegative,
    'is-loaderOpen': isLoaderOpen
  })

  return (
    <header className={classes}>
      <button className="header-button" onClick={onClickHome} data-click>
      <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.102198 8.30653L6.67997 15.862C6.83919 16.0417 7.11131 16.043 7.26461 15.8829C7.42042 15.7203 7.43185 15.4218 7.28393 15.2509L1.36138 8.44536L25.0772 8.44536C25.3042 8.44536 25.4883 8.24639 25.4883 8.00092C25.4883 7.75546 25.3042 7.55648 25.0772 7.55648L1.36138 7.55648L7.28393 0.750995C7.43197 0.580108 7.42285 0.278947 7.26461 0.118948C7.1065 -0.0410497 6.85206 -0.0450074 6.67997 0.139836L0.102198 7.69532C-0.0541451 7.90331 -0.0130341 8.15826 0.102198 8.30653Z" />
      </svg>
        Back
      </button>
      <button className="header-button" onClick={onClickAbout} data-click>About</button>
    </header>
  )
}

Header.propTypes = {
  isAboutOpen: PropTypes.bool,
  isNegative: PropTypes.bool,
  isLoaderOpen: PropTypes.bool,
  onClickAbout: PropTypes.func,
  onClickHome: PropTypes.func
}

export default Header
